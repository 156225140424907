import React from 'react';
import { Button, CircularProgress, Box, Typography, Divider } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { ToggleHistory } from 'views/Home/components/ChatBot/components/ToggleHistory';

interface CustomerViewProps {
    isLoading: boolean;
    handleGoToChat: () => void;
    alterSubscriptionUrl: string;
    userData: any;
    subscriptionData: any;
}

const CustomerView: React.FC<CustomerViewProps> = ({ isLoading, handleGoToChat, alterSubscriptionUrl, userData, subscriptionData }) => (
    <Box mt={1}>
        {isLoading ? (
            <CircularProgress />
        ) : (
            <Box sx={{ flex: 1, boxShadow: 3, borderRadius: 12, width: { xs: 325, md: '100%' }, mb: 2 }}>
                <Box sx={{ display: 'flex', p: 0.5, }}>
                    <Typography variant='h4' sx={{ m: 2 }}>Your plan</Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, height: '70%', paddingX: 2 }}>
                    <Box sx={{ flex: 1, pl: 0 }}>
                        <Typography variant='h6'>Name:</Typography>
                        <Typography variant='body1'>{userData.name}</Typography>
                        <Typography variant='h6'>Email:</Typography>
                        <Typography variant='body1'>{userData.email}</Typography>
                        <Typography variant='h6'>Subscription Status:</Typography>
                        <Typography variant='body1'>{userData.user_type === 'customer' ? 'Subscribed' : 'Not Subscribed'}</Typography>
                        <Typography variant='h6'>Price:</Typography>
                        <Typography variant='body1'>${(subscriptionData?.items[0].price.unit_amount / 100).toFixed(2)} /{subscriptionData?.items[0].price.recurring.interval}</Typography>
                        <Typography variant='h6'>Next Billing Date:</Typography>
                        <Typography variant='body1'>{new Date(subscriptionData?.current_period_end.seconds * 1000).toLocaleDateString()}</Typography>
                    </Box>
                    {/* <Divider orientation='vertical'/> */}
                    <Box sx={{ flex: 1, pl: { md: 2, xs: 0 }, mt: { md: 0, xs: 4 } }}>
                        <Typography variant='h5'>Current Subscription Perks:</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', marginY: 2 }}>
                            <CheckIcon color='success' />
                            <Typography variant='body1'>Unlimited access to the Darby chatbot - Ask all the questions you want</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', marginY: 2 }}>
                            <CheckIcon color='success' />
                            <Typography variant='body1'>Chat history - Continue any conversation from a previous session</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', marginY: 2 }}>
                            <CheckIcon color='success' />
                            <Typography variant='body1'>PDF lookup - Find the exact source text for your queries directly from the PDF</Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, alignItems: 'center', justifyContent: 'space-evenly', paddingTop: 0, height: { md: 50, xs: 100 } }}>
                    <Button variant="outlined" color="primary" onClick={handleGoToChat} sx={{ width: { xs: '75%', md: '25%' } }}>
                        GO TO CHAT
                    </Button>
                    <Button variant="contained" color="primary" href={alterSubscriptionUrl} target='_blank' sx={{ width: { xs: '75%', md: '25%' }, mt: { xs: 1, md: 0 } }}>
                        Alter Subscription
                    </Button>
                </Box>
            </Box>
            // <>
            //     <Typography variant="h6" gutterBottom>
            //         Thanks for your support <br></br>
            //         Now go try Darby!
            //     </Typography>
            //     <Button variant="outlined" color="primary" onClick={handleGoToChat} sx={{ mb: 2, mx: 1 }}>
            //         GO TO CHAT
            //     </Button>
            //     <Button variant="contained" color="primary" href={alterSubscriptionUrl} target='_blank' sx={{ mb: 2, mx: 1 }}>
            //         Alter Subscription
            //     </Button>
            // </>
        )}
    </Box>
);

export default CustomerView;