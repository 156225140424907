import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  updateDoc,
  collection,
  getDocs,
} from "@firebase/firestore";
import {
  Auth,
  User,
  createUserWithEmailAndPassword,
  deleteUser as gcpDeleteUser,
  sendEmailVerification,
} from "firebase/auth";
import { sendRequestedUserEmail, sendVerificationEmail } from "core/api/functions";
const db = getFirestore();

export const createUser = async (userAuth: any, additionalData = {}) => {
  if (!userAuth) return null;

  const userRef = doc(db, `users/${userAuth.uid}`);
  const docSnap = await getDoc(userRef);

  if (!docSnap.exists()) {
    await setDoc(userRef, {
      email: userAuth.email,
      ...additionalData,
    });
    return {
      uid: userAuth.uid,
      email: userAuth.email,
      role: "non-admin",
      ...additionalData,
    };
  }
  return docSnap.data();
};
//TODO: Throw errors for different cases (e.g. user already exists)

export const signUpAndSendEmail = async (
  credentials: SignUpCredentials,
  auth: Auth
) => {
  try {
    // Step 1: Create the Firebase Auth user
    const created_auth_user = await createUserWithEmailAndPassword(
      auth,
      credentials.email,
      credentials.password
    );

    if (!created_auth_user ) {
      throw new Error("Failed to create auth user");
    }

    // Step 2: Wait until the current user is fully populated
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("auth.currentUser is null after user creation.");
    }

    // Step 3: Create a Firestore user profile
    const newUser = await createUser(currentUser, {
      email: credentials.email,
      first_name: credentials.firstName,
      last_name: credentials.lastName,
      user_type: "client",
      email_verified: true,
      is_active: true,
      chat_custom_instructions: "",
      document_type: "DoD",
      save_history: true,
    });

    if (!newUser) {
      throw new Error("Failed to create Firestore user");
    }

    // Step 4: Send verification email to the user
    try {
      await sendVerificationEmail(credentials.email, credentials.firstName);
    }
    catch {
      console.log("Failed to send verification")
    }

    return true;
  } catch (error) {
    console.error("Error in signUpAndSendEmail:", error);

    if (auth.currentUser) {
      // If there was an error, clean up by deleting the user
      await gcpDeleteUser(auth.currentUser as User);
      await deleteUserFirestoreProfile(auth.currentUser.uid);
    }

    return false;
  }
};


//@ts-ignore
export const getUserProfile = async (userId) => {
  const userRef = doc(db, `users/${userId}`);
  const docSnap = await getDoc(userRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }
  console.log("User does not exist");
  return null; // User does not exist
};

//@ts-ignore
export const checkUserRole = async (userId) => {
  const userRef = doc(db, `users/${userId}`);
  const docSnap = await getDoc(userRef);

  if (docSnap.exists()) {
    return docSnap.data().role;
  }
  return null;
};

//@ts-ignore
export const updateUser = async (userId, updateData) => {
  const userRef = doc(db, `users/${userId}`);

  try {
    await updateDoc(userRef, updateData);
    return true;
  } catch (error) {
    console.error("Error updating user profile:", error);
    return false;
  }
};

//@ts-ignore
export const deleteUserFirestoreProfile = async (userId) => {
  const userRef = doc(db, `users/${userId}`);

  try {
    await deleteDoc(userRef);
    return true;
  } catch (error) {
    console.error("Error deleting user profile:", error);
    return false;
  }
};

//@ts-ignore
export const listAllUsers = async () => {
  const usersRef = collection(db, "users");
  const snapshot = await getDocs(usersRef);
  const userList = snapshot.docs.map((doc) => doc.data());
  return userList;
};