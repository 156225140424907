import React, { useState, useEffect } from 'react';
import { FormControl, Switch, CircularProgress } from '@mui/material';
import { getCurrentUserId } from 'core/api/api';
import { db } from 'core/config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export const ToggleHistory = () => {
    const [isChecked, setIsChecked] = useState(true);
    const [loading, setLoading] = useState(true);
    const userId = getCurrentUserId() ;

    useEffect(() => {
        const fetchUserSetting = async () => {
            if (userId) {
                const userDocRef = doc(db, 'users', userId);
                try {
                    const docSnap = await getDoc(userDocRef);
                    if (docSnap.exists()) {
                        const savedHistory = docSnap.data().save_history;
                        setIsChecked(savedHistory ?? true);
                    }
                } catch (error) {
                    console.error('Error fetching document: ', error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchUserSetting();
    }, [userId]);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setIsChecked(checked);

        if (userId) {
            const userDocRef = doc(db, 'users', userId);
            try {
                await updateDoc(userDocRef, {
                    save_history: checked
                });
            } catch (error) {
                // console.error('Error updating document: ', error);
            }
        }
    };

    if (loading) {
        return (
            <FormControl>
                <CircularProgress />
            </FormControl>
        );
    }

    return (
        <FormControl>
            <Switch
                checked={isChecked}
                onChange={handleChange}
                name="saveChatHistory"
                color="default"
                sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                        color: 'green',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'green',
                    },
                    '& .MuiSwitch-switchBase': {
                        color: 'gray',
                    },
                    '& .MuiSwitch-track': {
                        backgroundColor: 'gray',
                    }
                }}
            />
        </FormControl>
    );
};
