import { API_URL } from '../config/';
import { auth } from 'core/config/firebase'

export const getJwtToken = (): string | null => {
    return localStorage.getItem('token');
};

export const setJwtToken = (token: string): void => {
    localStorage.setItem('token', token);
}

export const removeJwtToken = (): void => {
    localStorage.removeItem('token');
}

export const refreshTokenIfNeeded = async (): Promise<void> => {
    const currentUser = auth.currentUser;

    if (currentUser) {
        const newToken = await currentUser.getIdToken(true); // Force refresh
        setJwtToken(newToken);
    } else {
        removeJwtToken();
    }
};

export const getHeaders = async (): Promise<HeadersInit> => {
    await refreshTokenIfNeeded(); // fixing timeout issues while retaining security
    const jwtToken = getJwtToken();
    return {
        'Content-Type': 'application/json',
        ...(jwtToken ? { 'Authorization': `Bearer ${jwtToken}` } : {}),
    };
};

export const getCurrentUserId = (): string | null => {
    return auth.currentUser?.uid || null
};

export const getChatHistory = async () => {
    const userId = getCurrentUserId()
    try {
        const response = await fetch(`${API_URL}/get-chat/${userId}`, {
            method: 'GET',
            headers: await getHeaders(), // refreshed before the request
        });
        if (!response.ok) {
            throw new Error('Failed to fetch chat history');
        }
        const data = await response.json();
        return data.messages;
    } catch (error) {
        console.error('Error:', error);
    }
};


export const saveChatHistory = async (messages: HistoryEntry[]) => {
    const userId = getCurrentUserId();
    try {
        const response = await fetch(`${API_URL}/save-chat`, {
            method: 'POST',
            headers: await getHeaders(),
            body: JSON.stringify({ user_id: userId, messages: messages }),
        });
        if (!response.ok) {
            throw new Error('Failed to save chat history');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};


export const sendChatMessage = async (query: string, sessionId: string, selectedFilter: string | null) => {
    let body;

    if (selectedFilter) {
        body = {
            query: query,
            sessionId: sessionId,
            type: selectedFilter,
            session_id: sessionId
        };

    } else {
        body = {
            query: query,
            sessionId: sessionId,
            session_id: sessionId
        };
    }

    try {
        const response = await fetch(`${API_URL}/answer`, {
            method: 'POST',
            headers: await getHeaders(),
            body: JSON.stringify(body),
        });


        if (!response.ok) {
            throw new Error('Failed to post message');
        }
        return response.json();
    } catch (error) {
        console.error('Error:', error);
    }
};

