import React, { useEffect, useState } from 'react';
import { Typography, Slide, Box, Drawer, styled, Button, IconButton, Divider, useTheme, Tooltip, CssBaseline, Toolbar, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import { db } from "core/config/firebase";
import { getDocs, collection, doc, getDoc, updateDoc, writeBatch, deleteDoc } from "firebase/firestore";
import { getCurrentUserId } from "core/api/api";
import { useAuth } from "contexts/AuthContext";
import { BookOutlined, Create, Campaign, Settings } from '@mui/icons-material';
import { useChat } from 'contexts/ChatContext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CustomInstructions from './components/CustomInstructions';
import LinearProgress from '@mui/material/LinearProgress';
import KnowledgeBase from '../KnowledgeBase';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { format } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ChatBot from '../ChatBot';
import { logoLight } from 'assets';
import { ToggleHistory } from '../ChatBot/components/ToggleHistory';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { FeedbackForm } from './components/FeedbackForm';


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

const HistorySidebar = () => {
  const [docNames, setDocNames] = useState<string[]>([]);
  const { userInfo } = useAuth();
  const uid = getCurrentUserId();
  const theme = useTheme();

  const { chatSessionList, switchChat, initializeNewSession, loadChatHistoryList, createNewChat, firstChatLoading, currentSessionId } = useChat();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [instructionText, setInstructionText] = useState<string>("");
  const [isOpen, setOpen] = useState(false);
  const [chatTitles, setChatTitles] = useState<{ [key: string]: string | null }>({});
  const [toolsAnchorEl, setToolsAnchorEl] = useState<null | HTMLElement>(null);
  const [knowledgeBaseAnchorEl, setKnowledgeBaseAnchorEl] = useState<null | HTMLElement>(null);
  const [knowledgeBaseOpen, setKnowledgeBaseOpen] = useState(false);
  const [toolsDialogOpen, setToolsDialogOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('additionalTools');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [chatIdToDelete, setChatIdToDelete] = useState<string | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleOpenForm = () => {
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  const handleToolsClick = () => {
    setSelectedMenu('additionalTools')
    setToolsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setToolsDialogOpen(false);
  };

  const handleKnowledgeBaseOpen = () => {
    setKnowledgeBaseOpen(true);
  };

  const handleKnowledgeBaseClose = () => {
    setKnowledgeBaseOpen(false);
  };

  const handleClose = () => {
    setToolsAnchorEl(null);
    setKnowledgeBaseAnchorEl(null);
  };


  // always grab the most recent values from firestore
  const handleDialogOpen = async () => {
    if (!uid) {
      console.error("User ID is not defined");
      return;
    }
    try {
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const latestInstructions = userDoc.data().chat_custom_instructions || "";
        setInstructionText(latestInstructions.replace(/^"|"$/g, ""));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setDialogOpen(true);
  };

  useEffect(() => {
    const fetchChatTitles = async () => {
      if (!uid) return;

      const titles: { [key: string]: string | null } = {};

      for (const session of chatSessionList) {
        const chatHistoryRef = doc(db, `users/${uid}/chat_history/${session.id}`);
        await getDoc(chatHistoryRef)
          .then((docData) => {
            if (docData.exists()) {
              const messages = docData.data()["messages"] || [];
              const title = messages[1]?.data?.chat_title;
              titles[session.id] = title || null;
            }
          })
          .catch((error) => {
            console.error("Error fetching chat title:", error);
          });
      }
      setChatTitles(titles);
    };

    fetchChatTitles();
  }, [uid, chatSessionList, db]);


  useEffect(() => {
    // Remove quotes from value
    const userCustomMessage = userInfo?.chat_custom_instructions || "";
    setInstructionText(userCustomMessage.replace(/^"|"$/g, ""));
  }, [userInfo]);

  // set new chat_custom_instructions in firestore
  const setInstructions = async (newInstructions: string) => {
    if (!uid) {
      console.error("User ID is not defined");
      return;
    }

    try {
      const userDocRef = doc(db, "users", uid);
      await updateDoc(userDocRef, {
        chat_custom_instructions: newInstructions,
      });
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleSidebarHistoryToggle = () => {
    setOpen(prevState => !prevState);
  };

  const handleSidebarHistoryOpen = () => {
    setOpen(true);
  };

  const handleSidebarHistoryClose = () => {
    setOpen(false);
  };

  const handleInitializeNewSession = async () => {
    setOpen(false);
    await initializeNewSession();
  };

  const handleSwitchChat = async (sessionId: string) => {
    setOpen(false);
    await switchChat(sessionId);
  };

  const deleteSingleChat = async (chatId: string) => {
    if (uid) {
      try {
        const userDocRef = doc(db, 'users', uid);
        const chatHistoryRef = collection(userDocRef, 'chat_history');

        const snapshot = await getDocs(chatHistoryRef);
        const chatCount = snapshot.size;

        const chatDocRef = doc(userDocRef, 'chat_history', chatId);

        await deleteDoc(chatDocRef);
        console.log('Chat deleted successfully.');

        if (chatId === currentSessionId) {
          handleInitializeNewSession();
        } else {
          loadChatHistoryList();
        }
      } catch (error) {
        console.error('Error deleting chat: ', error);
      }
    }
  };


  const clearChatHistory = async () => {
    if (uid) {
      try {
        const userDocRef = doc(db, 'users', uid);
        const chatHistoryRef = collection(userDocRef, 'chat_history');

        const snapshot = await getDocs(chatHistoryRef);

        if (snapshot.empty) {
          return;
        }

        const batch = writeBatch(db);
        snapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();
        console.log('Chat history cleared successfully.');
      } catch (error) {
        console.error('Error clearing chat history: ', error);
      }
      handleInitializeNewSession()
    }

  }
  const handleClearChatHistory = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmClear = () => {
    clearChatHistory();
    setOpenConfirmDialog(false);
  };
  const handleCancelClear = () => {
    setOpenConfirmDialog(false);
  };
  const handleDeleteChat = (chatId: string) => {
    setChatIdToDelete(chatId)
    setOpenDeleteDialog(true);
  }
  const handleConfirmDelete = () => {
    if (chatIdToDelete) {
      deleteSingleChat(chatIdToDelete);
    }
    setOpenDeleteDialog(false)
  }
  const handleCancelDelete = () => {
    setChatIdToDelete(null);
    setOpenDeleteDialog(false);
  }

  const drawerWidth = 240;

  useEffect(() => {
    loadChatHistoryList();
  }, [isOpen]);

  const formatTimestamp = (timestamp: string) => {
    const timestampNumber = parseFloat(timestamp);

    if (isNaN(timestampNumber)) {
      // console.log(timestampNumber)
      // console.error(`Invalid timestamp value: ${timestamp}`);
      return 'Invalid Date';
    }

    const date = new Date(timestampNumber);

    return format(date, 'dd MMM yyyy');
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={isOpen}
      >
        <DrawerHeader style={{ backgroundColor: '#30242e', display: 'flex', justifyContent: 'space-between' }}>
          <Box
            component="img"
            src={logoLight}
            sx={{ width: "10vw", maxWidth: 70, maxHeight: 70 }}
          />
        </DrawerHeader>
        <Divider />
        <Slide direction="right" in={isOpen} mountOnEnter unmountOnExit>
          <Box sx={{ height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Tooltip title="New Chat" placement="right" >
                <IconButton onClick={createNewChat} >
                  <Create />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close sidebar" placement='right' sx={{ alignSelf: 'flex-end' }}>
                <IconButton onClick={handleSidebarHistoryClose}>
                  {theme.direction === 'ltr' ? <ChevronLeftIcon color='error' /> : <ChevronRightIcon />}
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 0 }}>
              <Typography variant="h6" sx={{ paddingTop: 1, paddingBottom: 1 }}>
                Chat History
              </Typography>
              <Box sx={{ flexGrow: 1, overflow: 'auto', width: '100%', maxHeight: '70vh' }}>
                {chatSessionList.length > 0 ? (
                  <List>
                    {chatSessionList.map((historySessionObj: HistorySession, index) => {
                      const isActive = currentSessionId === historySessionObj.id;
                      const chatTitle = chatTitles[historySessionObj.id];
                      const formattedDate = formatTimestamp(historySessionObj.date_created);

                      return chatTitle ? (
                        <ListItem disablePadding key={historySessionObj.id || index}>
                          <ListItemButton
                            onClick={() => handleSwitchChat(historySessionObj.id)}
                            sx={{
                              backgroundColor: isActive
                                ? theme.palette.action.selected
                                : "inherit",
                              "&:hover": {
                                backgroundColor: isActive
                                  ? theme.palette.action.selected
                                  : theme.palette.action.hover,
                              },
                            }}
                          >
                            {firstChatLoading.isLoading && firstChatLoading.index === index ? (
                              <LinearProgress
                                sx={{
                                  width: "100%",
                                  color: theme.palette.primary.main,
                                }}
                              />
                            ) : (
                              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                <Box>
                                  <Typography variant="caption" sx={{ mb: 0.5 }}>
                                    {formattedDate}
                                  </Typography>
                                  <ListItemText
                                    primary={chatTitle}
                                    primaryTypographyProps={{ fontSize: '14px' }}
                                  />
                                </Box>
                                <Tooltip title="Delete chat">
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteChat(historySessionObj.id)

                                    }}
                                    edge="end"
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                                <Dialog open={openDeleteDialog} onClose={handleCancelDelete}>
                                  <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, color: 'white', height: '55px', mt: '-5px' }}>Confirm Delete Chat</DialogTitle>
                                  <DialogContent sx={{ mt: '20px', mb: '-10px', fontSize: '1.1rem' }}>
                                    Are you sure you want to delete this chat?
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleCancelDelete()
                                      }}
                                      color="primary">
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleConfirmDelete();
                                      }}
                                      color="primary" sx={{ color: 'red' }} >
                                      Delete
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </Box>

                            )}
                          </ListItemButton>
                        </ListItem>
                      ) : null;
                    })}
                  </List>
                ) : (
                  <Typography variant="body1" sx={{ ml: 8 }}>No chat history.</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Slide>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => {
              setDialogOpen(true)
            }}>
              <ListItemIcon>
                <Campaign />
              </ListItemIcon>
              <ListItemText primary={"Custom Instructions"} />
            </ListItemButton>
          </ListItem>
          {/* ))} */}
        </List>
      </Drawer>
      <Main open={isOpen} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ width: '70%' }}>
          {!isOpen &&
            <Tooltip title="Chat History" placement="bottom">
              <IconButton onClick={handleSidebarHistoryToggle} >
                <BookOutlined />
              </IconButton>
            </Tooltip>
          }
          {!isOpen &&
            <Tooltip title="New Chat" placement="bottom">
              <IconButton onClick={createNewChat} >
                <Create />
              </IconButton>
            </Tooltip>
          }
          {!isOpen &&
            <>
              <Tooltip title="Settings" placement="bottom">
                <IconButton onClick={handleToolsClick}>
                  <Settings />
                </IconButton>
              </Tooltip>
              <Dialog
                open={toolsDialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="tools-dialog-title"
                aria-describedby="tools-dialog-description"
                PaperProps={{
                  sx: {
                    width: isMobile ? '100%' : '50%',
                    height: isMobile ? '32%' : '30%'
                  },
                }}
              >
                <DialogTitle
                  sx={(theme) => ({
                    m: 0,
                    p: 1,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    position: 'relative',
                  })}
                  id="customized-dialog-title"
                >
                  Settings
                  <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={(theme) => ({
                      position: 'absolute',
                      right: 6,
                      top: 4,
                      color: 'white',
                    })}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <Box display="flex">
                    {/* Menu on the left */}
                    <Box sx={{ width: isMobile ? 100 : 150, mr: 5, mt: 2, ml: -2 }}>
                      <Typography
                        sx={{
                          cursor: 'pointer',
                          padding: 1,
                          borderRadius: 2,
                          border: '2px solid',
                          borderColor: selectedMenu === 'additionalTools' ? 'primary.main' : 'white',
                          backgroundColor: selectedMenu === 'additionalTools' ? 'primary.main' : 'background.paper',
                          color: selectedMenu === 'additionalTools' ? 'white' : 'text.primary',
                          '&:hover': {
                            color: selectedMenu === 'additionalTools' ? 'white' : 'primary.main',
                            borderColor: selectedMenu === 'chatHistory' ? 'primary.main' : 'white'
                          }
                        }}
                        onClick={() => setSelectedMenu('additionalTools')}
                      >
                        Additional Tools
                      </Typography>
                      <Typography
                        sx={{
                          cursor: 'pointer',
                          padding: 1,
                          mt: 0.5,
                          borderRadius: 2,
                          border: '2px solid',
                          borderColor: selectedMenu === 'chatHistory' ? 'primary.main' : 'white',
                          backgroundColor: selectedMenu === 'chatHistory' ? 'primary.main' : 'background.paper',
                          color: selectedMenu === 'chatHistory' ? 'white' : 'text.primary',
                          '&:hover': {
                            color: selectedMenu === 'chatHistory' ? 'white' : 'primary.main',
                            borderColor: selectedMenu === 'additionalTools' ? 'primary.main' : 'white'
                          }
                        }}
                        onClick={() => setSelectedMenu('chatHistory')}
                      >
                        Chat History
                      </Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem sx={{ ml: -4, mr: 1, mt: 1, mb: -5, height: 205 }} />
                    {/* Content on the right */}
                    <Box flex={1} sx={{ mt: 1 }}>
                      {selectedMenu === 'additionalTools' && (
                        <List>
                          <ListItem button onClick={() => { setDialogOpen(true) }}>
                            <ListItemIcon>
                              <Campaign />
                            </ListItemIcon>
                            <ListItemText primary="Custom Instructions" />
                          </ListItem>
                          <Divider />
                          <ListItem button onClick={() => { handleKnowledgeBaseOpen() }}>
                            <ListItemIcon>
                              <LocalLibraryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Knowledge Base" />
                          </ListItem>
                        </List>
                      )}
                      {selectedMenu === 'chatHistory' && (
                        <List>
                          <ListItem>
                            <ListItemIcon>
                              <ArchiveIcon />
                            </ListItemIcon>
                            <ListItemText primary="Save Chat History" />
                            <ToggleHistory />
                          </ListItem>
                          <Divider />
                          <ListItem button onClick={() => { handleClearChatHistory() }}>
                            <ListItemIcon sx={{ color: 'red' }}>
                              <DeleteOutlineIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary="Clear Chat History"
                              sx={{ color: 'red' }}
                            />
                          </ListItem>
                        </List>

                      )}
                      <Dialog
                        open={openConfirmDialog}
                        onClose={handleCancelClear}
                      >
                        <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, color: 'white', height: '55px', mt: '-5px' }}>Confirm Clear Chat History</DialogTitle>
                        <DialogContent sx={{ mt: '20px', mb: '-10px', fontSize: '1.1rem' }}>
                          Are you sure you want to clear your chat history?
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCancelClear} color="primary">
                            Cancel
                          </Button>
                          <Button onClick={handleConfirmClear} color="primary" sx={{ color: 'red' }} >
                            Clear
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  </Box>
                </DialogContent>
              </Dialog>
            </>
          }
        </Box>
        {/* Feeback */}
        <Box sx={{ width: '30%', position: 'absolute', right: '23px', display: 'flex', justifyContent:'flex-end' }}>
        {!isOpen && (
          <>
            <Tooltip title="Provide Feedback" placement="bottom">
              <IconButton onClick={handleOpenForm} >
                <AddCommentIcon />
              </IconButton>
            </Tooltip>

            <Dialog open={isFormOpen} onClose={handleCloseForm} maxWidth="sm" fullWidth>
            <DialogContent>
              <FeedbackForm open={isFormOpen} onClose={handleCloseForm}/>
            </DialogContent>
            </Dialog>
            </>
          )}
        </Box>

        <Box sx={{ width: '85%', alignSelf: 'center' }}>
          <CustomInstructions
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            instructionText={instructionText}
            setInstructionText={setInstructionText}
            setInstructions={setInstructions}
          />
          <KnowledgeBase open={knowledgeBaseOpen} onClose={handleKnowledgeBaseClose} />
          <ChatBot />
        </Box>
      </Main>
    </Box>
  );
};

export default HistorySidebar;