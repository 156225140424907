import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, TextField, Rating, Typography, Snackbar, Alert, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sendUserFeedback } from 'core/api/functions';
import { useAuth } from 'contexts/AuthContext'; 
import { logHyperDxAction } from 'core/api/hyperdx/logHyperDxAction';
import { IsLoading } from 'components';



interface FeedbackFormProps {
  open: boolean;
  onClose: () => void; // handle closing the form
}

export const FeedbackForm: React.FC<FeedbackFormProps> = ({ open, onClose }): JSX.Element => {
  const { userInfo } = useAuth(); 
  const [feedbackValues, setFeedbackValues] = useState({
    name: '',
    email: '',
    feedback: '',
    rating: 0,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state



  // Populate the email and name fields
  useEffect(() => {
    if (userInfo) {
      setFeedbackValues((prev) => ({
        ...prev,
        email: userInfo.email || '',  // Auto-fill the email
        name: `${userInfo.first_name} ${userInfo.last_name}` || ''  // Auto-fill name 
      }));
    }
  }, [userInfo]);

  // Log the HyperDX action when the form is opened
  useEffect(() => {
    if (open) {
      logHyperDxAction({
        action: 'Feedback',
        data: {
          name: feedbackValues.name,
          email: feedbackValues.email
        }
      });
    }
  }, [open, feedbackValues.name, feedbackValues.email]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFeedbackValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleRatingChange = (event: React.SyntheticEvent, value: number | null) => {
    setFeedbackValues((prev) => ({ ...prev, rating: value || 0 }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

     // Enable the loading state
     setIsLoading(true);

  
    if (!feedbackValues.feedback) {
      setSnackbarMessage('Please fill out the feedback field.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setIsLoading(false); // Turn off loading state

      return;
    }
  
    try {
      const message = await sendUserFeedback(
        feedbackValues.name,
        feedbackValues.email,
        feedbackValues.feedback,
        feedbackValues.rating
      );
  
      setSnackbarMessage(message);
      setSnackbarSeverity('success');
      setFeedbackValues({ name: '', email: '', feedback: '', rating: 0 });

      // Close the form after successful submission
      setTimeout(() => {
        onClose();
      }, 1500); // 3-second delay before closing the form
    } catch (error) {
      setSnackbarMessage('Error submitting feedback. Please try again.');
      setSnackbarSeverity('error');
    }
  
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ textAlign: 'center' }}>
        Tell us how we're doing
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500], // Default color is grey
            '&:hover': {
              color: 'red' // Change to red on hover
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box paddingX={3} paddingY={2}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Your Name"
                  name="name"
                  value={feedbackValues.name}
                  disabled // Disable field so it can't be changed
                  required
                />
              </Grid> */}
              {/* <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Your Email"
                  name="email"
                  type="email"
                  value={feedbackValues.email}
                  disabled // Disable field so it can't be changed
                  required
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Your Feedback"
                  name="feedback"
                  multiline
                  rows={4}
                  value={feedbackValues.feedback}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography component="legend"></Typography>
                <Rating
                  name="rating"
                  value={feedbackValues.rating}
                  onChange={handleRatingChange}
                  precision={0.5}
                />
                </Box>
              </Grid>
              <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
