import {
    doc,
    getDoc,
    getFirestore,
} from 'firebase/firestore';


const db = getFirestore();



export const getChatHistoryFromFirestore = async (userId: string, sessionId: string): Promise<HistoryEntry[]> => {

    // query firestore for the users.chat_history collection, return all chat data in ChatHistory type
    if (!userId) {
        throw Error("User ID is not defined");
    }
    try {
        // const docsSnapshot = await getDoc(collection(db, "users", userId, "chat_history"));

        const chatHistoryRef = doc(db, `users/${userId}/chat_history/${sessionId}`);

        const docData = await getDoc(chatHistoryRef);

        const chatHistoryMessages = docData.data();

        // console.log("chatHistoryMessages:", chatHistoryMessages);

        let chatHistoryOutput: HistoryEntry[] = [];

        if (chatHistoryMessages) {
            const messages = chatHistoryMessages["messages"];
            const lastMessage = messages[messages.length - 1];
            let lastTimestamp = new Date(lastMessage.data.additional_kwargs.timestamp * 1000).toISOString();
            // console.log("lastTimestamp:", lastTimestamp);
            for(let i = 0; i < messages.length; i=i+2) {
                try {
                    const darbyData = messages[i+1].data
                    // console.log("darbyData:", darbyData);
                    chatHistoryOutput.push({
                       user: messages[i]?.data.content || "",
                       darby: {
                        answer: darbyData?.content || "",
                        pdf_urls: darbyData?.response_metadata.pdf_urls || [""],
                        file_names: darbyData?.response_metadata.file_names || [""],
                        issuance_subjects: darbyData?.response_metadata.issuance_subjects || [""],
                        issuance_dates: darbyData?.response_metadata.issuance_dates || [""],
                        page_numbers: darbyData?.response_metadata.page_numbers || [""],
                       }
                    });
                } catch {
                    console.error("Error parsing chat history message:", messages[i]);
                }

            }

        } else {
            return [];
        }

        // console.log("chatHistoryOutput:", chatHistoryOutput);

        return chatHistoryOutput;
    } catch (error) {
        // console.error("Error fetching document names:", error);
        return [];
    }
};
