import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  Link,
  Typography,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  MenuItem,
  Tooltip,
  IconButton,
  Menu,
  Avatar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { logoLight } from "assets";
import { useNavigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

interface Settings {
  title: string;
  path: string;
}

function Navbar() {
  const { userInfo, logout, isSuperuser } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (userInfo) {
      setEmail(userInfo.email);
      setUserType(userInfo.user_type);
    }
  }, [userInfo]);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  let navItems: { title: string; path: string }[] = [];

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const settings: Settings[] =
    userInfo?.user_type === "client"
      ? [{ title: "Profile", path: "/profile" }]
      : [
          { title: "Home", path: "/" },
          { title: "Profile", path: "/profile" },
          { title: "Support", path: "/support-request" },
        ];

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseUserMenu = (navigatePath: string) => {
    return () => {
      setAnchorElUser(null);
      navigate(navigatePath);
    };
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <AppBar position="fixed">
        <Toolbar>
          <Box mr="auto">
            <Box
              component="img"
              src={logoLight}
              sx={{ width: "10vw", maxWidth: 70, maxHeight: 70 }}
            />
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
            {navItems.map((page, i) => (
              <Box marginLeft={4} key={i}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  aria-describedby={page.path}
                  sx={{
                    cursor: "pointer",
                    backgroundColor:
                      location.pathname === page.path
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main,
                    borderRadius: theme.shape.borderRadius,
                  }}
                >
                  <Link
                    component={RouterLink}
                    to={page.path}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      color="white"
                      sx={{
                        padding: theme.spacing(1),
                        borderRadius: theme.shape.borderRadius,
                      }}
                    >
                      {page.title}
                    </Typography>
                  </Link>
                </Box>
              </Box>
            ))}

            <Typography
              variant={"h6"}
              color={"white"}
              sx={{ marginLeft: 20, marginRight: 3 }}
            >
              {email}
            </Typography>
          </Box>

          {isMobile && (
            <MobileMenu
              handleLogout={handleLogout}
              navigate={navigate}
              navItems={navItems}
            />
          )}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>
                  {userInfo?.first_name && (
                    <>
                      {userInfo?.first_name.charAt(0)}
                      {userInfo?.last_name?.charAt(0)}
                    </>
                  )}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseNavMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.title}
                  onClick={handleCloseUserMenu(setting.path)}
                >
                  <Typography textAlign="center">{setting.title}</Typography>
                </MenuItem>
              ))}
              <Divider />
              <MenuItem key="Sign Out" onClick={handleLogout}>
                <Typography textAlign="center">Sign Out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

interface MobileMenuProps {
  handleLogout: () => void;
  navigate: (path: string) => void;
  navItems: { title: string; path: string }[];
}

function MobileMenu({ handleLogout, navigate, navItems }: MobileMenuProps) {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMobileNavigation = (path: string) => {
    navigate(path);
    setMobileOpen(false);
  };

  return (
    <>
      <Box
        alignItems={"center"}
        sx={{
          display: { xs: "flex", md: "none" },
          backgroundColor: theme.palette.primary.light,
          borderRadius: "8px",
          marginRight: 2,
        }}
      >
        {/* <Button
          aria-label="Menu"
          variant={"outlined"}
          onClick={handleDrawerToggle}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            color: "black",
          }}
        >
          <MenuIcon />
        </Button> */}
      </Box>
      <Drawer
        anchor={"right"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 250,
            borderRadius: "8px",
            color: theme.palette.text.primary,
          },
        }}
      >
        <Box
          onClick={handleDrawerToggle}
          sx={{
            width: 250,
          }}
        >
          <List>
            {navItems.map((page, index) => (
              <ListItemButton
                key={index}
                onClick={() => handleMobileNavigation(page.path)}
                sx={{
                  backgroundColor:
                    location.pathname === page.path
                      ? theme.palette.secondary.main
                      : theme.palette.primary.contrastText,
                }}
              >
                <ListItemText
                  primary={page.title}
                  sx={{
                    fontWeight:
                      location.pathname === page.path ? "bold" : "normal",
                  }}
                />
              </ListItemButton>
            ))}
          </List>
          <Divider />
          <ListItemButton color="inherit" onClick={handleLogout}>
            Sign Out
          </ListItemButton>
        </Box>
      </Drawer>
    </>
  );
}

export default Navbar;
